import { inject } from 'vue'
import HomeMonitor from 'public/src/pages/common/home_common/monitor.js'

/**
 * @typedef {import('../types/metrics').HideCompMonitorContext} HideCompMonitorContext 
 */

/**
 * 对隐藏的组件上报告警
 * 
 * @param {Record<string, any>} params
 * @param {MetricCompFilterContext} context 
 */
export function useHideCompMonitor(
  params,
  { propData, sceneData },
) {
  const metricsConfig = inject('metricsConfig', {})

  HomeMonitor.metricCompFilter({
    tags: {
      page: metricsConfig.pageName || '',
      component_type: propData?.styleType || '',
      reason: params?.tags?.reason || '',
      traceId: params?.tags?.traceId || sceneData?.traceId || '',
    },
    message: params?.message || ''
  })
}
