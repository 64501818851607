import { SLS_LOG_LEVEL, useHomeSlsLog } from '@modules/homepage/composables/use-home-sls-log'
import { ref, computed, toRefs } from 'vue'
import { transformImg } from '@shein/common-function'

/**
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderDataItem} SbcPlaceholderDataItem
 * @typedef {import('@modules/homepage/types/sbc').SbcPlaceholderMetaData} SbcPlaceholderMetaData
 */

export const isAnimationImageRegExp = /\.(gif)$/

export function reportFieldMissing({
  propData,
  sceneData,
  index,
  reason,
  item,
}) {
  // 发送日志报告异常
  useHomeSlsLog({
    logLevel: SLS_LOG_LEVEL.ERROR,
    tag: 'web_client_home_error_total',
    message: 'CCCShownFilterError',
    reason,
    extraInfo: {
      hrefType: item?.hrefType,
      hrefTarget: item?.hrefTarget,
    }
  }, {
    propData,
    sceneData,
    index
  })
}
export function preHandleForSbcDataItem({
  propData,
  sceneData,
  index,
  item,
}) {
  if (item.image?.src) {
    // 常规图片类型，让 BaseImg 处理转为 webp、并裁剪
    // 动态图保持原样不动、避免图花掉
    if (isAnimationImageRegExp.test(item.image.src)) {
      item._FE_isUnCut_ = 0
      item._FE_isAnimationImg_ = 1
    }
  } else {
    // 没有分类图，发送日志报告异常
    reportFieldMissing({
      propData,
      sceneData,
      index,
      reason: 'itemImageError',
      item,
    })
  }

  if (!item.smallTitle) {
    // 没有分类标题，发送日志报告异常
    reportFieldMissing({
      propData,
      sceneData,
      index,
      reason: 'itemTitleError',
      item
    })
  }
}

/** 从 Vue 组件的 props 中提取数据 */
export function deriveFromVueProps(vueProps) {
  const {
    propData: propDataFromProps,
    sceneData: sceneDataFromCommonProps,
    index: indexFromProps,
    context: contextFromCommonProps,
  } = toRefs(vueProps)
  const compProps = computed(() => (propDataFromProps.value.props || {}))
  const compMetaData = computed(() => (
    compProps.value.metaData || {}
  ))
  const compExtendData = computed(() => (
    sceneDataFromCommonProps.value.extendData
    || contextFromCommonProps.value?.content?.extendParse
    || {}
  ))

  const dataList = (compProps.value?.items || [])
  // 数据项需要预处理，比如加上一些提示信息字段
  dataList.forEach((item) => preHandleForSbcDataItem({
    propData: propDataFromProps.value,
    sceneData: sceneDataFromCommonProps.value,
    index: indexFromProps.value,
    item,
  }))
  const itemsDataList = ref(dataList)

  return {
    propDataFromProps,
    sceneDataFromCommonProps,
    contextFromCommonProps,
    indexFromProps,
    compProps,
    compMetaData,
    compExtendData,
    itemsDataList,
  }
}

export function customizedLeadingDebounce({ func, wait = 0, options = {} }) {
  const { onNotCall } = options
  let timerId
  let result
  let context

  const debounced = function (...args) {
    return new Promise(resolve => {
      context = this
      if (timerId) {
        clearTimeout(timerId)
      }

      // Must leading, call once immediately
      const callNow = !timerId
      timerId = setTimeout(() => {
        timerId = null
      }, wait)

      if (callNow) {
        result = func.apply(context, args)
        resolve(result)
      } else {
        onNotCall?.()
      }
    })
  }

  debounced.cancel = function () {
    if (timerId) {
      clearTimeout(timerId)
    }
    timerId = context = null
  }

  return debounced
}

/**
* 获取元素卡片样式
* 
* @param {{
*   compMetaData: import('vue').ComputedRef<SbcPlaceholderMetaData>
* }} params
*/
export function useCardStyle({ compMetaData }) {
  // 卡片化样式
  const isCardStyle = computed(() => {
    const {
      isCardShow = '0',
      margin: marginList = [],
      cardRadius = '0',
    } = compMetaData.value || {}

    if (isCardShow == '0') return false
    if (isCardShow == '1' || Number(cardRadius) > 0) return true

    return (
      Array.isArray(marginList)
      && marginList.some((item) => item > 0)
    )
  })
  const cardStyle = computed(() => {
    const { margin = [], cardRadius = '0' } = compMetaData.value || {}
    const marginList = (
      Array.isArray(margin) && margin.length === 4
        ? margin.slice()
        : Array(4).fill(0)
    )
    const marginStyle = (
      marginList
        .map((item) => `${Number(item)}px`)
        .join(' ')
    )
    const styleResult = {
      margin: marginStyle,
      overflow: 'hidden',
      borderRadius: `${cardRadius}px`,
    }

    return isCardStyle.value ? styleResult : {}
  })

  return cardStyle
}

/**
 * 获取根元素样式
 * 
 * @param {{
 *   compMetaData: import('vue').ComputedRef<SbcPlaceholderMetaData>
 * }} params
 */
export function useRootStyle({
  compMetaData,
}) {
  // 卡片化样式
  const cardStyle = useCardStyle({ compMetaData })

  // 背景图片样式
  const bgImgStyle = computed(() => {
    const bgImgSrc = compMetaData.value?.bgImage?.src
    if (bgImgSrc) {
      return {
        backgroundSize: '100% auto', // prd 要求背景图片宽度100%，高度自适应
        backgroundImage: `url(${transformImg({ img: bgImgSrc })})`
      }
    }
    return { background: '#fff' }
  })

  // 组件根元素样式
  const rootStyle = computed(() => {
    return {
      width: '100%',
      paddingTop: (
        compMetaData.value.showTopSpacing
          ? '8px'
          : '0'
      ),
      ...cardStyle.value,
      ...bgImgStyle.value,
    }
  })

  return rootStyle
}
