import HomeSlslog from 'public/src/pages/common/home_common/slslog.js'

/**
 * @typedef {import('../types/metrics.js').SlsLogParams} SlsLogParams
 * @typedef {import('../types/metrics.js').SlsLogContext} SlsLogContext
 */

export const SLS_LOG_LEVEL = {
  INFO: 1,
  WARN: 2,
  ERROR: 3,
}

/**
 * @param {SlsLogParams} params
 * @param {SlsLogParams} context
 */
export function useHomeSlsLog(
  // Params
  {
    logLevel = 1, 
    tag = '',
    message = '', 
    reason = '', 
    immediate = false,
    extraInfo
  } = {},
  // Context
  {
    propData = {},
    sceneData = {},
    index = 0,
  } = {},
) {
  HomeSlslog.slsCompFilter(
    {
      logLevel,
      tag,
      message,
      reason,
      extraInfo,
      module: propData?.styleType || '',
      tabName: sceneData?.tabName || '',
      pageName: sceneData?.pageType || '',
      cccId: propData?.componentId || propData?.id || '',
      traceId: extraInfo?.traceId || sceneData?.traceId || '', // traceId，便于通过日志查询数据，extraInfo入参traceId优先，否则接受统一的traceId
      position: `${index + 1}`,
    },
    immediate,
  )
}
