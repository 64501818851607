import { computed, inject } from 'vue'
import { markPoint } from 'public/src/services/mark/index.js'
import { CccLink, isInSpa } from 'public/src/pages/components/ccc/common/ccc-link.js'
import { preFetchOrGetPageUrl } from 'public/src/services/lazyComponent/lazy-hydrate/pre-fetch/utils.js'
import { metricCccClick } from 'public/src/pages/common/business-monitor/common.js'
import { preloadGoodsRequest } from 'public/src/pre_requests/modules/productList/spaForHydration.js'
// 统一告警，日志人口，方便维护
import { useHideCompMonitor } from './use-hide-comp-monitor'
import { useHomeSlsLog } from './use-home-sls-log'

/**
 * 用来替代 Vue 2 写法中几乎随处都在引用的 mixin
 * public/src/pages/components/ccc/components/mixins/mixin.js
 * @param {{
 *   sceneData: any,
 *   propData: any,
 *   index: number
 *   cateLinks: Record<string, any>
 * }} param
 * @returns 
 */
export function useCommonMixin(
  { sceneData, propData, index, cateLinks = {}, context = {} },
) {
  // inject:
  const injectsDropCart = inject('injectsDropCart', () => { })
  const metricsConfig = inject('metricsConfig', {})

  // Computed:
  const metaData = computed(() => {
    return propData?.props?.metaData || {}
  })
  const compCode = computed(() => {
    const { componentKey, id } = propData
    return `${componentKey}${index}${id}`
  })
  const cccLink = computed(() => {
    return new CccLink({
      sceneData,
      propData,
    })
  })
  const constantData = computed(() => {
    const { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, GB_cssRight, WEB_CLIENT, LAZY_IMG_PIXEL, langPath, PUBLIC_CDN, LAZY_IMG_SOLID_COLOR = '', appLanguage  } = typeof window === 'undefined' ? context || {} : gbCommonInfo || {}
    const IS_RW = WEB_CLIENT !== 'shein'
    return Object.assign({}, { IMG_LINK, LAZY_IMG, LAZY_IMG_SQUARE, LAZY_IMG_SOLID_COLOR, GB_cssRight, IS_RW, LAZY_IMG_PIXEL, langPath, PUBLIC_CDN, appLanguage })
  })
  /** 
   * 卡片化字段为 false，或者边距、圆角 这2项都获取失败或为空时，
   * 代表非卡片化样式，展示原有样式 
   */
  const homeCompIsCardStyle = computed(() => {
    const {
      isCardShow = '0',
      margin: marginList = [],
      cardRadius = '0',
    } = metaData.value || {}
    if (Number(isCardShow) !== 1) return false
    if (Number(isCardShow) || Number(cardRadius) > 0) return true
    return Array.isArray(marginList) && marginList.some((item) => Number(item) > 0)
  })
  const homeCompCardStyle = computed(() => {
    const { margin = [], cardRadius = '0' } = metaData.value || {}
    const marginList =
      Array.isArray(margin) && margin.length === 4
        ? margin.slice()
        : Array(4).fill(0)
    const marginStyle = marginList
      .map((item) => `${Number(item)}px`)
      .join(' ')
    const styleResult = {
      margin: marginStyle,
      overflow: 'hidden',
      transform: 'translate3d(0, 0, 0)',
    }
    styleResult.borderRadius = `${Number(cardRadius)}px`

    return (
      homeCompIsCardStyle.value
        ? styleResult
        : {}
    )
  })

  // Methods:
  /** 跳转页面 */
  const jumpLink = ({ url, hrefType, routeType = 'push', scene = 'cccx' }) => {
    if (url.indexOf('javascript:;') > -1) return

    try {
      // 点击CCC 组件监控
      if (metricsConfig?.reportMetrics?.cccClick) {
        metricCccClick({
          page: metricsConfig?.pageName || '',
          component_type: propData?.styleType || '',
          position: `${index + 1}`,
          business_block: propData?.businessBlock || '',
        })
      }
    } finally {
      if (isInSpa({ hrefType }) && hrefType !== 'activityNew') {
        markPoint('toNextPageClick', 'public')
        handleClickToList({ url, hrefType })
        if (window?._gb_app_) {
          window._gb_app_?.$router?.[routeType]?.(url)
        } else { // 兼容多页存在无_gb_app_实例case
          location.href = url
        }
      } else {
        sessionStorage.setItem('navtrack_to', 'page_campaigns')
        sessionStorage.setItem('navtrack_point', Date.now())
        location.href = preFetchOrGetPageUrl({ url, options: { isReturnUrl: true, scene } })
      }
    }
  }
  /** 获取埋点信息 */
  const getAnalysisData = (id, config = {}, setCode = false) => {
    const { item, index: itemIndex = 0, prefix, callback, ...arg } = config

    const data = {
      sceneData,
      propData,
      item,
      compIndex: index, // 组件所在位置
      index: itemIndex, // 当前item在组件中的位置
      ...arg,
    }

    const code =
      typeof setCode === 'boolean' && setCode
        ? compCode
        : typeof setCode === 'string'
          ? setCode
          : ''

    return {
      id,
      code,
      data,
      prefix: prefix ?? sceneData.pageFrom,
      callback: typeof callback === 'function' ? callback : (void 0)
    }
  }
  /** 获取商品类埋点信息 */
  const getProductAnalysis = (id, config) => {
    const {
      type = 'click', index, prefix, item, isNew, cate,
      fault, tab, flash_type, poskey, isJumpToList,
      name = '', compIndex = '', act_nm, isPopup,
      style, extendSaParams, callback, abtest,
      useBffApi, setCode = false
    } = config

    const analysisData = {
      item: cate,
      product: item,
      name,
      index,
      prefix,
      tab,        // 当前的tab
      flash_type, // ⚠️也不要有类似这样的具有特殊类型特定组件的字段
      poskey,
      // ⚠️不要扩展类似下面两个变量的类型，应该新开一个字段统一维护
      isJumpToList, // 是否跳到列表(闪购、一口价列表等)
      fault, // 商品是否是容错请求之后的商品
      act_nm,
      isNew,
      isPopup,
      style,
      extendSaParams, // 外部直接传入sa相关数据
      callback,
      abtest,
      useBffApi, // 是否bff组件
    }

    // type 为曝光的时候 需要给埋点信息加上唯一code (商品类)
    if (typeof compIndex === 'number') Object.assign(analysisData, { compIndex })
    let code = type === 'expose' ? `${compCode.value}_${index}_product` : ''
    if (typeof setCode === 'string') code = setCode

    return getAnalysisData(id, analysisData, code)
  }
  /** 跳转落地页通用方法 */
  const handleClickToPage = (config, needAnalysis = true) => {
    const {
      hrefType, hrefTarget, webClickUrl,
      isBff, item_loc, relativeUrl = '',
      itemIndex, poskey, adp
    } = config

    const url = cccLink.value.getFullLink({
      item: {
        hrefType,
        hrefTarget,
        webClickUrl,
        ...(relativeUrl ? { relativeUrl } : {}),
        ...(adp ? { adp } : {}),
        ...(item_loc ? { item_loc: item_loc } : {})
      },
      index: itemIndex,
      cateLinks,
      compIndex: index,
      isBff,
    })
    needAnalysis && sendAnalysis({
      hrefType,
      hrefTarget,
      itemIndex,
      poskey,
    })
    if (!url || url.indexOf('javascript:;') > -1) return
    jumpLink({ url, hrefType })
  }
  // 跳转列表页（第一次使用单页水合）
  const handleClickToList = ({ url, hrefType }) => {
    const listTypes = ['real', 'itemPicking', 'frontCat'] // 真实、选品、 前台类目
    if (!listTypes.includes(hrefType)) return // 只有跳转到列表页才会触发单页水合

    preloadGoodsRequest.triggerNotice(url)
    preloadGoodsRequest.click(url)
  }
  const sendAnalysis = ({ hrefType, hrefTarget, itemIndex, poskey }) => {
    daEventCenter.triggerNotice({
      daId: '2-22-2',
      bindData: {
        sceneData,
        propData,
        compIndex: index,
        index: itemIndex,
        poskey,
        item: {
          hrefType,
          hrefTarget,
        },
      },
    })
  }
  /** 隐藏组件告警 */
  const hideCompMonitor = (params) => {
    useHideCompMonitor(params, {
      propData,
      sceneData,
      index,
    })
  }
  /** 组件点击告警 */
  const clickCompMonitor = () => {
    if (!metricsConfig?.reportMetrics?.cccClick) return
    metricCccClick({
      page: metricsConfig?.pageName || '',
      component_type: propData?.styleType || '',
      position: `${index + 1}`,
      business_block: propData?.businessBlock || '',
    })
  }
  /** 隐藏组件日志上报 */
  const hideCompSls = (config = {}) => {
    useHomeSlsLog(config, {
      propData,
      sceneData,
      index,
    })
  }

  return {
    injectsDropCart,
    metricsConfig,

    metaData,
    code: compCode,
    cccLink,
    constantData,
    homeCompIsCardStyle,
    homeCompCardStyle,

    jumpLink,
    getAnalysisData,
    getProductAnalysis,
    handleClickToPage,
    handleClickToList,
    sendAnalysis,
    hideCompMonitor,
    clickCompMonitor,
    hideCompSls,
  }
}

